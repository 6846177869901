//driver-side
.right-side-wrapper {
  width: 400px;
  .MuiButtonBase-root {
    min-width: 135px !important;
    // min-height: 100% !important;
  }
  .MuiTab-textColorSecondary.Mui-selected {
    color: #4695f6 !important;
  }

  .MuiTab-wrapper {
    font-size: 12px !important;
  }

  .MuiTab-labelIcon {
    min-height: 62px !important;
  }

  .MuiTab-root {
    line-height: 1.3 !important;
  }
  .drivers-list-title {
    min-height: 35px !important;
    padding: 7px 0;
    background-color: #eee;
    p {
      text-align: center;
      font-weight: bold;
    }
  }

  .driver-list-wrapper {
    max-width: 100% !important;
    height: 99% !important;
    .MuiDivider-root {
      margin-left: 0;
      margin-right: 0;
    }
    .show-on-map-btn {
      margin-top: 10px;
      min-width: 100px !important;
      span {
        font-size: 11px !important;
      }
      .MuiSvgIcon-root {
        font-size: 18px !important;
      }
    }
    .list-item {
      cursor: pointer;
      .list-content-style {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .orders-counter {
          border: 1px lightgrey solid;
          border-radius: 50%;
          width: 55px;
          height: 55px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          margin-left: 7px;
          p {
            color: #4695f6;
            font-size: 13px;
          }
        }

        .list-item-icon {
          color: #999;
        }
        p {
          color: #999;
          margin: 0;
        }
      }
    }
  }
}

//driver trip
.driver-trip-header {
  min-height: 40px !important;
  background-color: #eee;
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  padding-top: 3px;
  .driver-trip-title {
    display: flex;
    align-items: center;
    color: black;
    p {
      text-align: center;
      font-weight: bold;
    }
    .back-icon {
      font-size: 30px;
      margin-right: 5px;
      margin-left: 5px;
      cursor: pointer;
      color: #4695f6;
    }
  }
}

.driver-trip-wrapper {
  .MuiListItem-root {
    display: flex;
    justify-content: center;
  }
  .driver-trip-content {
    max-width: 100% !important;
    height: 500px;
    overflow: auto;
    .MuiDivider-root {
      margin-left: 0;
      margin-right: 0;
    }

    .order-wrapper {
      display: flex;
      align-items: center;
      margin-bottom: 5px;
      .high-priority {
        color: red;
        margin-right: 5px;
      }
    }

    .list-item {
      cursor: pointer;
      .list-content-style {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .orders-counter {
          border: 1px lightgrey solid;
          border-radius: 50%;
          width: 55px;
          height: 55px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          p {
            color: #4695f6;
            font-size: 13px;
          }
        }

        .list-item-icon {
          color: #999;
        }
        p {
          color: #999;
          margin: 0;
        }
      }
    }
  }
  .no-orders-title {
    text-align: center;
    color: red;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  }
}

body {
	margin: 0;
}

html,
body,
#root,
.app {
	height: 98%;
}

.map {
	height: 100%;
}

.MuiPaper-elevation1 {
	box-shadow: none !important;
}

.MuiTableCell-body{
font-size: 13px !important;
}

.MuiChip-labelSmall{
	padding: 0 !important
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.lmfs-container {
  height: 100%;
  display: flex;
}
.lmfs-map {
  height: 100%;
}
.lmfs-mapContainer {
  width: 100%;
}

.lmfs-tasks-header{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  .back-icon{
    font-size: 30px;
    margin-right: 5px;
    margin-left: 5px;
    cursor: pointer;
    color: #4695f6;
  };

  .driver-id-wrapper{
    margin-left: 10px;
    .driver-title{
      font-family: Roboto;
      margin-bottom: 0;
      font-size: 11px;
    }
    .driver-id{
      font-size: 36px;
      font-family: Roboto;
    }
  }
}

.MuiDialog-paperWidthSm {
  // max-width: 50% !important;
  min-width: 50% !important;
  height: 100% !important;
}

// .MuiDialogContent-root {
//   display: flex;
//   justify-content: center;
//   align-items: center;
// }

@media (max-width: 1075px) {
  .MuiDialog-paperWidthSm {
    min-width: 100% !important;
  }
}

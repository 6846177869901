.assign-orders-wrapper {
	display: flex;

	.MuiListItem-container {
		width: 95% !important;
	}

	.free-drivers-list-title {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		font-family: sans-serif;
		margin-left: 10px;
		margin-bottom: 10px;
		font-size: 16px;
		font-weight: bold;
		color: #333;

		.list-title-wrapper {
			display: flex;
			align-items: flex-end;
			.MuiSvgIcon-root {
				font-size: 1.2rem;
			}
			p {
				margin: 0px;
				font-size: 14px;
			}
		}
		.all-free-drivers-checkbox {
			.MuiCheckbox-colorSecondary.Mui-checked {
				color: #0175ff;
			}
		}
	}

	.MuiListItemSecondaryAction-root {
		// right: 20px !important;
		padding: 10px 0px !important;
	}

	.MuiListItem-gutters {
		padding-left: 0px !important;
	}

	.ready-orders-wrapper {
		margin-bottom: 20px;
		margin-right: 30px;
		display: flex;
		justify-content: center;
		display: inline-block;
	}

	.MuiList-root {
		height: 650px;
		overflow-y: scroll;
		padding-left: 10px;
	}
}

.optimized-list-wrapper {
	.MuiListItemIcon-root {
		min-width: 30px !important;
	}

	.optimized-list-header {
		background-color: #999;
		display: flex;
		justify-content: space-between;
		border-radius: 5px;
	}

	.order-item-text {
		cursor: pointer;
	}
}

.MuiDialogContent-dividers {
	padding: 16px 10px !important;
}
